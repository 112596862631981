
'use strict';

document.addEventListener('DOMContentLoaded', function () {
  var menuAction = document.querySelector('#menu-action');

  menuAction.addEventListener('click', function () {
    this.classList.toggle('is-open');
    document.querySelector('.navigation').classList.toggle('is-open');
  });
});
